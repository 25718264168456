import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const partnersCarousels = document.querySelectorAll(
    '.partners-carousel-block'
  );

  if (0 < partnersCarousels.length) {
    partnersCarousels.forEach(function (partnersCarousel) {
      new Swiper(
        partnersCarousel.querySelector('.partners-carousel-block__carousel'),
        {
          draggable: true,
          loop: true,
          slidesPerView: 1.37,
          spaceBetween: 10,
          a11y: {
            paginationBulletMessage: '',
            nextSlideMessage: 'Prochaine nouvelle',
            prevSlideMessage: 'Nouvelle précédente',
          },
          navigation: {
            nextEl: partnersCarousel
              .closest('.partners-carousel-block')
              .querySelector('.partners-block__nav.swiper-button-next'),
            prevEl: partnersCarousel
              .closest('.partners-carousel-block')
              .querySelector('.partners-block__nav.swiper-button-prev'),
          },
          pagination: {
            el: partnersCarousel
              .closest('.partners-carousel-block')
              .querySelector(
                '.partners-carousel-block__nav-container .swiper-pagination'
              ),
            type: 'fraction',
          },
          breakpoints: {
            600: {
              slidesPerView: 2,
            },
            769: {
              slidesPerView: 2.6,
            },
            1200: {
              slidesPerView: 3.635,
              spaceBetween: 30,
            },
          },
        }
      );
    });
  }
});
